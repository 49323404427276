import React from 'react';

export const astronautBallon = (
  <svg viewBox="0 0 175 1336" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m85.894 1260.49 32.651-122.72c8.023-30.15 16.102-60.62 19.165-92.7 12.781-133.965-62.908-271.145-36.013-400.361 5.313-25.523 14.431-48.972 21.484-73.586 18.439-64.341 22.417-137.196 11.243-205.911-10.944-67.305-36.134-134.174-27.277-201.296"
      stroke="#FF8F15"
      stroke-width="6"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <circle cx="102.451" cy="125.737" r="14.869" fill="#fff" />
    <path
      d="m104.085 135.074 3.136-2.5 1.616 2.74c-2.662 1.533-5.289 2.454-8.358 2.226-.101-.009-1.103-.144-1.175-.231L89.92 125.87l2.198-6.343 6.178 2.177 2.145-1.666 11.174 6.854-7.207 5.832-1.908-2.402 1.732-4.943-1.527-.534-2.07 5.91 3.447 4.322.003-.003Z"
      fill="#CD509C"
    />
    <path
      d="M96.174 141.314c.286.101.575.19.863.274l-1.665 1.334c.225-.023.45-.052.675-.072 5.258-.503 10.588.124 15.727 1.322 1.579.04 3.158.046 4.738-.011.499-.018 1.002-.05 1.501-.093.24-.02 1.75-.317.401-.035.861-.181 1.727-.317 2.584-.528 1.051-.26 2.082-.595 3.119-.906l-9.049-3.173a3.775 3.775 0 0 0 3.967-2.486l4.747-13.547c.9.032 1.787-.222 2.529-.731a4.15 4.15 0 0 0 1.588-2.087c.26-.733.309-1.527.147-2.29a4.246 4.246 0 0 0-1.077-2.032c-2.188-2.301-6.106-1.49-7.152 1.49-.537 1.536-.207 3.346 1.343 4.674l-2.313 6.594a3.706 3.706 0 0 0-.892-.441l-.222-.078c1.25-7.68-3.141-15.383-10.738-18.045-8.523-2.989-17.857 1.501-20.842 10.024-2.989 8.523 1.501 17.857 10.024 20.843h-.003Zm-7.449-19.942c2.486-7.091 10.276-10.836 17.367-8.353 7.091 2.486 10.835 10.276 8.352 17.367-2.485 7.091-10.275 10.835-17.366 8.352-7.091-2.483-10.836-10.275-8.353-17.366Z"
      fill="#CD509C"
    />
    <path
      d="M80.407 176.881c0 .788.525 1.314 1.313 1.314h41.207c.655 0 1.313-.526 1.313-1.314v-25.113H80.407v25.113ZM125.814 141.403h-46.98c-.789 0-1.315.526-1.315 1.314v5.119c0 .788.526 1.314 1.314 1.314h46.984c.655-.003 1.313-.529 1.313-1.317v-5.116c0-.788-.525-1.314-1.313-1.314h-.003Z"
      fill="#CE509D"
    />
    <path
      d="M102.324 0C70.923 0 45.429 25.494 45.429 56.895c.092 10.934 2.078 19.486 5.679 26.557l28.959 57.917c.479-.026.958-.04 1.44-.026.913.026 1.828.043 2.74.066L55.753 84.425h93.144L120.32 141.58l4.16-.009 29.063-58.122c3.756-8.01 5.621-17.485 5.679-26.557C159.219 25.494 133.722 0 102.324 0Zm48.294 80.752H53.975c-3.563-7.134-4.877-15.062-4.877-23.86 0-1.943.145-3.848.344-5.734l15.256 9.176a1.865 1.865 0 0 0 2.064-.173l17.093-13.767 17.32 13.767c.644.517 1.648.517 2.295 0l16.977-13.767 17.436 13.767a1.875 1.875 0 0 0 2.064.173l15.256-9.176c.202 1.886.344 3.794.344 5.734 0 8.509-1.542 16.833-4.932 23.86h.003Z"
      fill="#CE509D"
    />
    <path
      d="m83.857 46.397 17.321 13.766c.643.517 1.648.517 2.295 0l16.977-13.766 17.435 13.766a1.877 1.877 0 0 0 2.065.173l15.256-9.175c.202 1.885.343 3.794.343 5.734 0 8.508-1.541 16.832-4.931 23.86H53.975C50.412 73.62 49.1 65.692 49.1 56.895c0-1.943.144-3.849.344-5.734L64.7 60.336a1.865 1.865 0 0 0 2.064-.173l17.092-13.766Z"
      fill="#F4DBEA"
    />
    <g clip-path="url(#clip0_1742_946)">
      <path
        d="M20.004 1308.2c.25 10.99 9.35 19.7 20.326 19.45 10.975-.25 19.669-9.36 19.418-20.35-.25-11-9.35-19.7-20.326-19.45-10.975.25-19.67 9.36-19.418 20.35Z"
        fill="#fff"
      />
      <path
        d="M32.814 1298.1a11.645 11.645 0 0 1 6.402-2.25c2.313-.09 4.605.5 6.586 1.71a11.8 11.8 0 0 1 4.55 5.09 11.79 11.79 0 0 1 .974 6.75 11.719 11.719 0 0 1-2.928 6.14 11.622 11.622 0 0 1-5.844 3.44c-2.249.55-4.61.41-6.785-.4a11.81 11.81 0 0 1-5.437-4.11l-.044-.06c-3.913-5.36-2.455-12.66 2.522-16.31h.004Z"
        fill="#FF8F15"
      />
      <path
        d="M1.54 1301.3c.673 4.21 5.61 6.29 9.027 3.78 1.759-1.29 2.768-3.54 2.06-6.18l7.553-5.54c.165.42.383.81.65 1.17l.47.64c-6.262 7.69-6.694 18.63-.88 26.59 5.813 7.95 16.35 10.83 25.548 7.17l.477.65a5.157 5.157 0 0 0 3.307 2.03 5.068 5.068 0 0 0 3.755-.9l4.098-3.01a5.036 5.036 0 0 0 1.997-3.31 5.128 5.128 0 0 0-.92-3.78l-.036-.05 17.78-13.03a1.877 1.877 0 0 0 .706-1.94l-.056-.24a6.366 6.366 0 0 0-.127-.58 49.046 49.046 0 0 0-8.156-18.31 50.42 50.42 0 0 0-14.97-13.43 5.24 5.24 0 0 0-.603-.38l-.247-.15a1.89 1.89 0 0 0-1.06-.29c-.373.01-.734.14-1.035.36l-17.787 13.04-.034-.05a5.116 5.116 0 0 0-3.314-2.01 5.023 5.023 0 0 0-3.754.9l-15.516 11.37a5.614 5.614 0 0 0-3.345-1.11c-1.204 0-2.375.39-3.338 1.1a5.7 5.7 0 0 0-1.898 2.43 5.674 5.674 0 0 0-.352 3.06Zm50.616-2.83a15.563 15.563 0 0 1 2.976 11.58 15.381 15.381 0 0 1-2.035 5.73 15.272 15.272 0 0 1-4.086 4.48 15.215 15.215 0 0 1-5.504 2.55c-1.987.47-4.05.54-6.066.21a15.222 15.222 0 0 1-5.69-2.16 15.49 15.49 0 0 1-4.433-4.18c-5.324-7.29-3.209-16.85 3.277-21.6a15.332 15.332 0 0 1 11.456-2.77c4.041.63 7.675 2.85 10.105 6.16Z"
        fill="#FF8F15"
      />
      <path
        d="M91.775 1333.89a5.099 5.099 0 0 0 4.759-5.42l-3.322-20.01a5.91 5.91 0 0 0-3.223-4.89l-.46-.23-13.311-6.77 16.979-12.45 30.435 12.09.627.24h.005c1.655.64 3.506.48 5.036-.41l25.706-15.05H155a4.922 4.922 0 0 0 1.945-6.48 4.908 4.908 0 0 0-6.35-2.31l-26.404 10.16-21.832-9.24 25.207-7.05.867-.17a6.094 6.094 0 0 0 4.621-4.86l3.737-20.69a4.997 4.997 0 0 0-3.492-5.66 4.98 4.98 0 0 0-6.003 2.85l-5.696 17.1-37.696 4.61-.362.08a8.029 8.029 0 0 0-3.752 2.04l-17.188 12.22.226-13.89 16.697-12.65a5.12 5.12 0 0 0 .28-6.88 5.086 5.086 0 0 0-6.795-.9l-19.53 14.17a5.956 5.956 0 0 0-2.424 4.68l-.005.46-.443 30.86v.01a11.761 11.761 0 0 0 6.269 10.17l25.643 12.95 3.981 17.34v.01a5.112 5.112 0 0 0 5.275 3.97h-.001Z"
        fill="#FF8F15"
      />
    </g>
    <defs>
      <clipPath id="clip0_1742_946">
        <path
          fill="#fff"
          transform="rotate(88.694 -552.942 697.013)"
          d="M0 0h98.469v156.506H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export const leftMountain = (
  <svg viewBox="0 0 182 2657" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1742_948)">
      <path
        d="M77.722 1191.66c-3.852 3.08-9.03 7.2-14.038 11.2 16.255 16.07 44.706 37.24 44.706 37.24l-13.098 18.66s38.382 38.64 45.708 43.16l-70.175 67 9.979 25.54-29.056 48.84 39.867 48.55-30.302 30.54c-8.16 21.66-23.284 58.53-34.297 87.4C21.902 1621.86-1.011 1691-1.011 1691V912c27.056 117 61.565 245.63 78.727 279.66h.006Z"
        fill="#093266"
      />
      <path
        d="M-1 1438.5v-203l84.186 74.05-3.894-26.52 10.254 26.52 15.719-7.76-5.342-24.01 12.265 23.29 6.743-3.89 22.021 4.61-88.408 84.1-53.55 52.61H-1ZM104.661 1240.93l-8.876-3.65v3.65l-18.833-15.48 12.987 15.48-15.814-2.69 21.162 20.92 13.098-18.66-3.73.42.006.01ZM78.75 1192.48l-14.037 11.2-21.379-20.65 10.907 1.07-7.215-30.33 17.963 31.95 3.104-2.2 10.657 8.96Z"
        fill="#9164CC"
      />
      <path
        d="M80.995 1394.43H71.08v2.85l-9.74-9.38 4.222 9.38-7.146 1.61-8.116-11-25.777 25.92 27.23 29.49L81 1394.43h-.005ZM91.97 1491.87l-14.312-9.74 1.644 8.19-9.957 3.88-12.34-20.7 8.01 20.7-9.214 4.75-12-18.55-9.629-1.94-7.034-9.05L-1 1467.68V2269l142.5-372.5-130-133 50.391-241.72 30.08-29.91Z"
        fill="#9164CC"
      />
    </g>
    <g clip-path="url(#clip1_1742_948)">
      <path
        d="m102.299 437.753-17.972-14.364 55.928-47.222-16.777-23.417S172.62 303.281 182 297.488L92.151 211.48l12.777-32.755-37.202-61.232L-.007 0v1127L17.5 847 173 691.5l-54.5-83-16.201-170.747Z"
        fill="#093266"
      />
      <path
        d="M0 120.943v272.223l107.977-105.553-4.985 34.018 13.129-34.018 20.126 9.956-6.84 30.793 15.704-29.869 8.633 4.991 28.195-5.997L68.745 189.713 0 120.943Z"
        fill="#9064CB"
      />
      <path
        d="m135.473 375.624-11.364 4.673v-4.673l-24.113 19.845 16.628-19.845-20.248 3.45 27.095-26.324 16.777 23.417-4.775-.536v-.007ZM169.225 691.155l-11.364 4.673v-4.673L133.749 711l16.627-19.845-20.248 3.45 27.095-26.323L174 691.699l-4.775-.537v-.007ZM102.299 437.753l-17.973-14.364-27.373 26.486 13.965-1.371-9.237 38.901 22.999-40.98 3.973 2.819 13.646-11.491Z"
        fill="#9164CC"
      />
      <path
        d="M105.172 178.752H92.478v-3.647l-12.471 12.028 5.406-12.028-9.149-2.071-10.392 14.106L0 122.131V-.007l105.172 178.759Z"
        fill="#9064CB"
      />
    </g>
    <defs>
      <clipPath id="clip0_1742_948">
        <path
          fill="#fff"
          transform="matrix(-1 0 0 1 141 912)"
          d="M0 0h142v1745H0z"
        />
      </clipPath>
      <clipPath id="clip1_1742_948">
        <path
          fill="#fff"
          transform="rotate(-180 91 607.5)"
          d="M0 0h182v1215H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export const rightMountain = (
  <svg viewBox="0 0 135 1794" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_1742_947)">
      <path
        d="M60.16 266.52c3.66 2.932 8.583 6.864 13.344 10.674-15.453 15.312-42.502 35.49-42.502 35.49l12.453 17.78S6.965 367.291 0 371.596l66.716 63.852-9.487 24.341 27.623 46.548-37.902 46.269 28.809 29.106c7.757 20.641 22.136 55.778 32.606 83.288 4.862 11.502 26.645 77.394 26.645 77.394V0c-25.722 111.5-58.53 234.084-74.846 266.52h-.005Z"
        fill="#093266"
      />
      <path
        d="M135 501.759V308.297l-80.036 70.576 3.702-25.28-9.749 25.28-14.944-7.399 5.079-22.882-11.66 22.196-6.41-3.71-20.937 4.396 84.05 80.149 50.91 50.136H135ZM34.548 313.471l8.438-3.473v3.473l17.904-14.747-12.346 14.747 15.034-2.564-20.118 19.94-12.453-17.78 3.546.399-.005.005ZM59.18 267.302l13.346 10.675 20.325-19.683-10.37 1.019 6.86-28.908-17.077 30.453-2.95-2.095-10.133 8.539Z"
        fill="#9164CC"
      />
      <path
        d="M57.047 459.764h9.427v2.71l9.26-8.938-4.015 8.938 6.794 1.54 7.716-10.483 24.507 24.695-25.889 28.111-27.804-46.573h.005ZM46.612 552.621l13.608-9.286-1.564 7.808 9.467 3.699 11.731-19.728-7.616 19.728 8.76 4.522 11.41-17.674 9.153-1.847 6.688-8.63L135 529.567v763.663L-.476 938.237l123.592-126.75-47.907-230.361-28.597-28.505Z"
        fill="#9164CC"
      />
    </g>
    <g clip-path="url(#clip1_1742_947)">
      <path
        d="m60.243 1473.7 13.134 10.51-40.87 34.55 12.26 17.14S8.855 1572.09 2 1576.33l65.659 62.93-9.337 23.97 27.186 44.8 49.497 85.97V969.389l-12.793 204.871L8.577 1288.04l39.827 60.73 11.839 124.93Z"
        fill="#093266"
      />
      <path
        d="M135 1705.51v-199.18l-78.907 77.23 3.643-24.89-9.594 24.89-14.707-7.29 4.998-22.53-11.476 21.86-6.309-3.66-20.604 4.39 82.719 78.86L135 1705.51ZM36 1519.16l8.305-3.42v3.42l17.62-14.52-12.15 14.52 14.796-2.52-19.8 19.26-12.26-17.14 3.49.4ZM11.336 1288.29l8.304-3.42v3.42l17.62-14.52-12.15 14.52 14.796-2.52-19.8 19.26-12.26-17.14 3.49.4ZM60.243 1473.7l13.134 10.51 20.003-19.38-10.205 1.01 6.75-28.47-16.806 29.99-2.904-2.07-9.972 8.41Z"
        fill="#9164CC"
      />
      <path
        d="M58.143 1663.21h9.277v2.67l9.113-8.8-3.95 8.8 6.685 1.51 7.594-10.32L135 1704.64V1794l-76.857-130.79Z"
        fill="#9164CC"
      />
    </g>
    <defs>
      <clipPath id="clip0_1742_947">
        <path fill="#fff" d="M0 0h135v1663H0z" />
      </clipPath>
      <clipPath id="clip1_1742_947">
        <path fill="#fff" transform="translate(2 905)" d="M0 0h133v889H0z" />
      </clipPath>
    </defs>
  </svg>
);
