import Section from '../../../section';
import React, { useRef } from 'react';
import { astronautBallon, leftMountain, rightMountain } from './icons';

import {
  Icon,
  Text,
  chakra,
  Box,
  Heading,
  Divider,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChakraBox } from '../../../../components';
import { useSectionParallaxValues } from '../../../../hooks';
import { useTransform } from 'framer-motion';

const PricingSection = () => {
  const sectionRef = useRef<HTMLElement>();
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
    lg: false,
    xl: false,
    '2xl': false,
  });
  const { yProgress: ballonProgress } = useSectionParallaxValues(
    sectionRef as React.RefObject<HTMLDivElement>,
    {
      progressFactor: isMobile ? 0.85 : 0.8,
    },
  );
  const LeftMountain = () => (
    <ChakraBox
      pos="absolute"
      left={{ base: '0vw', sm: '0vw', '2xl': '-1.5vw' }}
      top={{
        base: '76%',
        mm: '72%',
        ml: '71%',
        sm: '76%',
        md: '20%',
        lg: '20%',
        xl: '20%',
        '2xl': '20%',
      }}
      w={{
        base: '22vw',
        sm: '22vw',
        md: '12.5vw',
        lg: '12.5vw',
        xl: '12.5vw',
        '2xl': '12.5vw',
      }}
      h="250vh"
      style={{
        y: useTransform(ballonProgress, [0, 1], ['0vh', '-60vh']),
      }}
    >
      <Icon h="100%" w="100%" viewBox="0 0 182 2657">
        {leftMountain}
      </Icon>
    </ChakraBox>
  );

  const RightMountain = () => (
    <ChakraBox
      pos="absolute"
      left={{
        base: '78%',
        sm: '78%',
        md: '26%',
        lg: '26%',
        xl: '26%',
        '2xl': '26%',
      }}
      top={{
        base: '60%',
        mm: '58%',
        ml: '56%',
        sm: '60%',
        md: '15%',
        lg: '15%',
        xl: '15%',
        '2xl': '15%',
      }}
      w={{
        base: '22vw',
        sm: '22vw',
        md: '11vw',
        lg: '11vw',
        xl: '11vw',
        '2xl': '12vw',
      }}
      h="250vh"
      style={{
        y: useTransform(ballonProgress, [0, 1], ['0vh', '60vh']),
      }}
    >
      <Icon h="100%" w="100%" viewBox="0 0 135 1794">
        {rightMountain}
      </Icon>
    </ChakraBox>
  );

  const SeparatorView = () => (
    <Box
      w={0.5}
      h="85%"
      bg="brand.800"
      left="37%"
      top="5%"
      position="absolute"
      display={{
        base: 'none',
        sm: 'none',
        md: 'block',
        lg: 'block',
        xl: 'block',
        '2xl': 'block',
      }}
    ></Box>
  );

  const AstronautBalloon = () => (
    <ChakraBox
      pos="absolute"
      left={{
        base: '25%',
        sm: '25%',
        md: '8%',
        lg: '8%',
        xl: '8%',
        '2xl': '8%',
      }}
      top={{
        base: '54%',
        ms: '54%',
        mm: '52%',
        ml: '52%',
        sm: '54%',
        md: '5%',
        lg: '5%',
        xl: '5%',
        '2xl': '5%',
      }}
      w={{
        base: '40vw',
        sm: '40vw',
        md: '18vw',
        lg: '18vw',
        xl: '18vw',
        '2xl': '18vw',
      }}
      h="250vh"
      style={{
        y: useTransform(ballonProgress, [0, 1], ['0vh', '120vh']),
      }}
    >
      <Icon h="100%" w="100%" viewBox="0 0 175 1336">
        {astronautBallon}
      </Icon>
    </ChakraBox>
  );

  const BigHeadText = ({ text }: { text: string }) => (
    <Heading
      fontWeight={800}
      mb={8}
      color="brand.800"
      fontSize="2.5rem"
      lineHeight="3.75rem"
      letterSpacing="0.05em"
    >
      {text}
    </Heading>
  );

  const MediumHeadText = ({ text }: { text: string }) => (
    <Heading
      fontWeight={600}
      mb={4}
      color="brand.800"
      fontSize="2.0rem"
      lineHeight="3.25rem"
      letterSpacing="0.05em"
    >
      {text}
    </Heading>
  );

  const SmallHeadText = ({ text }: { text: string }) => (
    <Heading
      fontWeight={600}
      mb={2}
      color="brand.800"
      fontSize="1.2rem"
      lineHeight="3.25rem"
      letterSpacing="0.05em"
    >
      {text}
    </Heading>
  );

  const ItalicSmallHeadText = ({ text }: { text: string }) => (
    <Heading
      maxW="40rem"
      fontWeight={100}
      color="brand.800"
      as="i"
      fontSize="1.063rem"
      lineHeight="1.688rem"
      letterSpacing="0.1em"
    >
      {text}
    </Heading>
  );

  const ParagraphText = ({ text }: { text: string }) => (
    <Text
      maxW="40rem"
      fontWeight={100}
      color="brand.800"
      fontSize="1.063rem"
      lineHeight="1.688rem"
      letterSpacing="0.1em"
      mb={4}
    >
      {text}
    </Text>
  );

  const PricingText = () => (
    <Box
      position="absolute"
      left={{
        base: '10%',
        sm: '10%',
        md: '42%',
        lg: '42%',
        xl: '42%',
        '2xl': '42%',
      }}
      top={{
        base: '2%',
        sm: '2%',
        md: '4.5%',
        lg: '4.5%',
        xl: '4.5%',
        '2xl': '4.5%',
      }}
      right={12}
    >
      <BigHeadText text="Pricing details" />
      <MediumHeadText text="Interchange revenue" />
      <ParagraphText
        text={`
            You earn 0.5% on the net card purchase volume-based on the
            card interchange revenue.
        `}
      />
      <br />
      <Divider variant="dashed" borderColor="brand.800" mb={4} />
      <br />
      <MediumHeadText text="KYC by account level" />
      <ParagraphText
        text={`
            Cuenca has four account levels, which have increasing KYC 
            requirements and limits. The highest of the levels has no 
            transaction or balance limits. The Mx$15 cost applies to 
            each incremental level.
        `}
      />
      <SmallHeadText text="Level 1" />
      <ParagraphText
        text={`
            Based on self-declared info (including name, date of 
            birth, place of birth, profession and home address) 
            and accepting Cuenca's Terms and Conditions.
        `}
      />
      <ParagraphText
        text={`
            As part of the initial registration checks the user's 
            info against black-lists (OFAC, PEP, UIF) and verifying 
            their identity in real-time with RENAPO, the Mexican 
            National Public Registry.
        `}
      />
      <ItalicSmallHeadText text="Limits include" />
      <br />
      <br />
      <ItalicSmallHeadText text="1. Mx$5,000 monthly deposit limit " />
      <br />
      <ItalicSmallHeadText text="2. Mx$6,500 maximum balance " />
      <SmallHeadText text="Level 2" />
      <ParagraphText
        text={`
            In addition to the requirements for level 1, 
            the user has to upload a federal government 
            issued ID (such as INE, passport or residency card).
        `}
      />
      <ItalicSmallHeadText text="1. Mx$6,500 maximum balance" />
      <br />
      <ItalicSmallHeadText text="2. No limit on deposit balance" />
      <SmallHeadText text="Level 3" />
      <ParagraphText
        text={`
            In addition to the requirements for level 2, 
            the user has to upload a proof of address 
            that's been issued within the prior three months.
        `}
      />
      <ItalicSmallHeadText text="Limits include" />
      <br />
      <ItalicSmallHeadText text="1. $65,000 MXN monthly deposit limit" />
      <br />
      <ItalicSmallHeadText text="2. No limit on deposit balance" />
      <SmallHeadText text="Level 4" />
      <ParagraphText
        text={`
            In addition to the requirements for level 3, 
            the user has to perform a liveness test by 
            uploading a video of their face—similar to 
            ctivating FaceID. A level 4 account has no 
            limits on deposits or their balance, and 
            it also allows cash deposits up to Mx$65,000 
            per month.
        `}
      />
      <br />
      <Divider variant="dashed" borderColor="brand.800" mb={4} />
      <MediumHeadText text="Account management" />
      <ParagraphText
        text={`
            The US$0.25 monthly fee applies to Monthly Active 
            Users—users that performs at least one transaction 
            in a given calendar month.
        `}
      />
      <br />
      <Divider variant="dashed" borderColor="brand.800" mb={4} />
      <MediumHeadText text="Transfers" />
      <ParagraphText
        text={`
            The US$0.13 transfer fee allows for real-time 
            24/7 transfers between Mexican financial 
            institutions via SPEI or between two Cuenca accounts.
        `}
      />
      <br />
      <Divider variant="dashed" borderColor="brand.800" mb={4} />
      <MediumHeadText text="Bill payments" />
      <ParagraphText
        text={`
            Connect enables bill payments at over 80 
            service providers—including at&t, Telcel, 
            CFE and others for US$0.25 per payment.
        `}
      />
      <br />
      <Divider variant="dashed" borderColor="brand.800" mb={4} />
      <MediumHeadText text="Cash deposits" />
      <ParagraphText
        text={`
            Cash deposits are accepted at over 18,000 
            locations across Mexico like 7-Eleven, 
            Circle K and Farmacias del Ahorro for 
            US$0.75 per deposit.
        `}
      />
      <br />
      <Divider variant="dashed" borderColor="brand.800" mb={4} />
      <MediumHeadText text="Cards" />
      <ParagraphText
        text={`
            As stated in the income section, you 
            earn 0.5% on all net card purchase volume.
        `}
      />
      <SmallHeadText text="Virtual cards" />
      <ParagraphText
        text={`
            There's absolutely no cost to you to issue virtual cards.
        `}
      />
      <SmallHeadText text="Printing and packaging" />
      <Text
        maxW="40rem"
        fontWeight={100}
        color="brand.800"
        fontSize="1.063rem"
        lineHeight="1.688rem"
        letterSpacing="0.1em"
        mb={4}
      >
        Physical cards are printed in Mexico with chip + PIN and contactless
        payment support. Our manufacturer has a minimum order requirement of
        15,000 cards per design; however, we understand the need to experiment
        while you get up and running. You can also use the design from
        <chakra.span color={'brandSecondary.400'} fontWeight={800}>
          {' '}
          Tarjetas Cuenca{' '}
        </chakra.span>
        without needing to worry about minimum orders. In both cases, we offer
        the printing and packaging of the cards at US$5.00 per card.
      </Text>
      <SmallHeadText text="Logistics and delivery" />
      <ParagraphText
        text={`
            Once the physical cards and printed and packaged, 
            we can deliver them to you in bulk at no additional 
            cost. We also offer logistics and delivery services 
            through our own warehousing, logistics teams and 
            several delivery services to optimize for cost 
            and user experience for US$5.00 per card. Users 
            will typically receive cards within 1–2 business 
            days. It can take longer to deliver to rural areas. 
            Our API provides real-time tracking info for your 
            users to see exactly where their card is and when 
            it's expected to arrive. 
        `}
      />
      <SmallHeadText text="Chargebacks" />
      <ParagraphText
        text={`
            Our customer support dashboard will give 
            your team the ability to manage and 
            dispute chargebacks. We'll also work 
            with you to determine the best methods 
            to reduce fraud for you and your customers. 
        `}
      />
      <MediumHeadText text="Invoicing" />
      <ParagraphText
        text={`
            You're billed in 
            US Dollars from a US bank account. 
        `}
      />
      <br />
      <Divider variant="dashed" borderColor="brand.800" mb={4} />
      <br />
      <Text
        maxW="40rem"
        fontWeight={100}
        color="brand.800"
        fontSize="1.063rem"
        lineHeight="1.688rem"
        letterSpacing="0.1em"
        mb={4}
      >
        Please contact us at
        <chakra.span color={'brandSecondary.400'} fontWeight={800}>
          {' '}
          integrations@arteria.xyz{' '}
        </chakra.span>
        with any questions.
      </Text>
    </Box>
  );

  return (
    <Section
      w="100vw"
      h={{
        base: '7600px',
        ms: '8500px',
        mm: '8000px',
        ml: '7400px',
        sm: '7600px',
        md: '4900px',
        lg: '4200px',
        xl: '4000px',
        '2xl': '4000px',
      }}
      bg="white"
      ref={sectionRef as React.RefObject<HTMLDivElement>}
    >
      <LeftMountain />
      <RightMountain />
      <SeparatorView />
      <AstronautBalloon />
      <PricingText />
    </Section>
  );
};
export default PricingSection;
